<template>
  <div>
    <header class="flex flex-ac flex-js">
      <img src="@/assets/images/wap_logo.png" />
      <a href="https://loan.xiuyekeji.com/" target="_blank">登录</a>
    </header>

    <section class="banner">
      <img src="@/assets/images/banner_cen.png" />
    </section>

    <section class="core flex flex-ac">
      <h4>核心项目</h4>
      <div class="core_item flex flex-ac flex-js">
        <img src="@/assets/images/mediate.png" />
        <div>
          <p>商事调解</p>
          <span
            >通过依法成立的商事调解中心，对金融借贷纠纷开展线上音视频调解</span
          >
        </div>
      </div>
      <div class="core_item flex flex-ac flex-js">
        <img src="@/assets/images/just.png" />
        <div>
          <p>线上赋强公证</p>
          <span>系统线上对接公证机构，对调解达成的调解文书进行赋强公证</span>
        </div>
      </div>
      <div class="core_item flex flex-ac flex-js">
        <img src="@/assets/images/file.png" />
        <div>
          <p>批量卷宗系统</p>
          <span>高效批量整理，确保全面符合特定文书规范与要求</span>
        </div>
      </div>
    </section>

    <section class="mediate">
      <div>
        <h4>“商调+赋强公证”</h4>
        <h4>速解金融借贷纠纷强保履约率</h4>
      </div>
      <img src="@/assets/images/mediate_bg.png" />
      <div class="mediate-con">
        <p>通过调解的方式化解纠纷，降低委外合规风险，减少进入诉讼程序案件量</p>
        <div class="item">
          <span class="dot"></span>选择调解机构并提交调解申请
        </div>
        <div class="item"><span class="dot"></span>组织商事调解，协商沟通</div>
        <div class="item"><span class="dot"></span>批量线上申请赋强公证</div>
        <div class="item"><span class="dot"></span>确认生效</div>
      </div>
    </section>

    <section class="advantage">
      <h4>六大核心优势</h4>
      <p class="desc">为金融借贷纠纷提供一站式高效率批量化的技术服务</p>
      <div class="advantage-item flex flex-ac">
        <img src="@/assets/images/team.png" />
        <div>
          <p>专业技术团队</p>
          <p>超百人技术团队以商事调解专长驱动业务成长</p>
        </div>
      </div>
      <div class="advantage-item flex flex-ac">
        <img src="@/assets/images/flexible.png" />
        <div>
          <p>灵活与安全性</p>
          <p>按需定制公证服务，严守保密原则</p>
        </div>
      </div>
      <div class="advantage-item flex flex-ac">
        <img src="@/assets/images/profession.png" />
        <div>
          <p>专业与权威性</p>
          <p>资深商事调解团队，高效执行，公信卓著</p>
        </div>
      </div>
      <div class="advantage-item flex flex-ac">
        <img src="@/assets/images/batch.png" />
        <div>
          <p>批量高效和便捷性</p>
          <p>赋强公证迅捷解纷，灵活调解省时增效</p>
        </div>
      </div>
      <div class="advantage-item flex flex-ac">
        <img src="@/assets/images/risk.png" />
        <div>
          <p>纠纷预防与风险评估</p>
          <p>专业建议与风险评估，有效防范纠纷隐患</p>
        </div>
      </div>
      <div class="advantage-item flex flex-ac">
        <img src="@/assets/images/money.png" />
        <div>
          <p>专业技术团队</p>
          <p>融合商事调解与公证赋强优势，一站式化解金融借贷纠纷</p>
        </div>
      </div>
    </section>

    <section class="about">
      <h4>关于我们</h4>
      <img src="@/assets/images/about.png" />
      <div>
        四川雅安修业科技服务有限公司，2021年成立于四川雅安市，致力于通过科技能力和商事调解组织防范和化解金融借贷风险，
        构建一站式全方位、全流程金融借贷纠纷服务平台。通过公司
        &nbsp;“e站调解管理系统”&nbsp;
        赋能全国范围内调解组织，提升线上调解能力，实现无纸化高效办公能力。
      </div>
      <div>
        公司秉承 &nbsp;“公正、专业、高效、便捷”&nbsp;
        的服务理念，通过不断优化服务流程、提升服务质量，为金融机构主体提供更加优质、高效的纠纷解决途径。
      </div>
    </section>

    <section class="mission">
      <div class="mession-item flex">
        <img src="@/assets/images/mission.png" />
        <div>
          <p class="f">使命</p>
          <p>强化商事调解，筑牢金融借贷风险防线</p>
        </div>
      </div>
      <div class="mession-item flex">
        <img src="@/assets/images/vision.png" />
        <div>
          <p class="f">愿景</p>
          <p>减矛盾，筑诚信，共筑和谐社会</p>
        </div>
      </div>
    </section>

    <footer>
      <p class="title">四川雅安修业科技服务有限公司</p>
      <div class="flex footer-item">
        <p>总&nbsp;经 &nbsp;理:</p>
        <p>曹鹤 | caohe@xiuyekeji.com</p>
      </div>
      <div class="flex footer-item">
        <p>公司地址：</p>
        <p>四川省雅安市名山区蒙阳镇水井街88号11幢12层1206号</p>
      </div>
      <div class="flex footer-item">
        <p>法律支持：</p>
        <p>四川全盈律师事务所</p>
      </div>
      <div class="line"></div>
      <p class="record">
        Copyright © 2021-2025XIUYEKEJI Corporation.All Rights Reserved.
      </p>
      <p class="record">备案号：蜀ICP备2024028841号</p>
    </footer>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "修业科技",
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "修业科技,修业科技官网,雅安修业科技有限公司",
      },
      {
        name: "description",
        content:
          "主要针对金融痛点，利用互联网加密、光闸数据交换等技术，使数据实现安全交换共享从而达到人民法院的认可和支持，实现法院与系统平台的数据标准统一，为金融借贷纠纷提供的一站式、高效批量化处理的在线诉讼技术服务。为金融机构（律师事务所等）提供小金额借款合同纠纷案件纯线上批量申请立案、批量保全、调解、批量申请执行等10多项诉讼技术服务功能，将诉讼全流程服务从线下搬到线上“一键式处理”，案件材料处理采用全电子档案的方式，同时响应了人民法院全流程无纸化办案新模式，提高了企业对借贷纠纷案件的诉讼效率和诉讼数量，案件全流程自动化跟踪处理。",
      },
    ],
    link: [
      {
        rel: "asstes",
        href: "https://www.xiuyekeji.com/wap",
      },
    ],
  },
  data() {
    return {
      show: false,
      current: 1,
      reasonCurrent: 1,
      footerContact: [
        {
          title: "总经理：彭德伟",
          info: `电话：13548003846 <br> 邮箱：pengdewei@xiuyekeji.com`,
        },
        {
          title: "商务总监：吴天",
          info: "电话：13880348070 <br> 邮箱：3431877460@qq.com",
        },
        {
          title: "四川雅安修业科技服务有限公司",
          info: "四川省雅安市名山区蒙阳镇水井街88号11幢12层1206号",
        },
      ],
      menu: [
        {
          title: "首 页",
          id: "home",
          url: require("@/images/wap/nav_01@2x.png"),
        },
        {
          title: "业务流程",
          id: "flow",
          url: require("@/images/wap/nav_02@2x.png"),
        },
        {
          title: "关于我们",
          id: "about",
          url: require("@/images/wap/nav_03@2x.png"),
        },
        {
          title: "创始人团队",
          id: "process",
          url: require("@/images/wap/nav_04@2x.png"),
        },
        {
          title: "招聘信息",
          id: "contact",
          url: require("@/images/wap/nav_05@2x.png"),
        },
      ],
      advertisements: [
        {
          url: require("@/images/icon_01@2x.png"),
          title: "全流程反馈",
          info: "案件处理流程节点全程追踪，反馈自动化。",
        },
        {
          url: require("@/images/icon_02@2x.png"),
          title: "专业透明",
          info: "利用司法公开的特点，营造稳定、公平、透明、可预期的金融环境，提供有力的司法服务和保障。",
        },
        {
          url: require("@/images/icon_03gxbj@2x.png"),
          title: "高效便捷",
          info: "线上实现法院、律所、金融机构等数据标准统一，诉讼材料在线批量处理。",
        },
      ],
      environments: [
        {
          title: "诉讼材料批量生成",
          url: require("@/images/icon_business_01@2x.png"),
        },
        {
          title: "批量移送法院起诉",
          url: require("@/images/icon_business_02@2x.png"),
        },
        {
          title: "法院批量立案保全",
          url: require("@/images/icon_business_03@2x.png"),
        },
        {
          title: "客户调解回款",
          url: require("@/images/icon_business_04@2x.png"),
        },
        {
          title: "结案",
          url: require("@/images/icon_business_05@2x.png"),
        },
      ],
      processDatas: [
        {
          url: require("@/images/pic__photo_01@2x.png"),
          name: "刘 东",
          position: "创始人、董事长",
          info: "互联网金融服务行业接触代表，坚信科技改变金融，并在一线金融服务行业深耕十余年，受邀参与过多次地方及央视重量级活动。",
        },
        {
          url: require("@/images/pic__photo_02@2x.png"),
          name: "彭德伟",
          position: "联合创始人、总经理",
          info: "<div style='font-weight: bold;'>西南财经大学 金融管理</div>历任摩尔龙集团副总裁及总监，一直从事研究不良资产司法处置模式。凭借多年经验，2020年成立了整合金融、司法、律所的修业科技公司，力争打造国内一流的“一站式金融借贷纠纷服务平台”。",
        },
        {
          url: require("@/images/pic__photo_03@2x.png"),
          name: "曹 鹤",
          position: "联合创始人、副总经理",
          info: "<div style='font-weight: bold;'>成都理工大学 经济学</div>历任利信大区总，鸿特大区总，澳美海外顾问。对个人信用贷款产品以及贷后有着多年的从业管理经验，专注于为金融机构不良资产的处理提供解决方案。",
        },
        {
          url: require("@/images/pic__photo_04@2x.png"),
          name: "文裕光",
          position: "技术总监",
          info: "<div style='font-weight: bold;'>内江师范大学 通信工程</div>在政法体系信息系统平台耕耘多年，拥有丰富的司法系统平台设计、研发、实施、维护经验。带领超100人技术团队，全面负责平台研发工作。",
        },
        {
          url: require("@/images/pic__photo_06@2x.png"),
          name: "吴 天",
          position: "商务总监",
          info: "<div style='font-weight: bold;'>西南政法大学 法学专业</div> 曾任成都交通投资集团收费站稽查，成都市公安局某特警队。历任摩尔龙集团多个直营公司总经理，擅长分析产品运营市场法规及相关规定，对产品在市场的投放有着精准把控。",
        },
        {
          url: require("@/images/pic__photo_07@2x.png"),
          name: "赵建平",
          position: "四川全盈律师事务所律师 首席法务官",
          info: "<div style='font-weight: bold;'>西南政法大学 法学专业</div> 先后进入金融机构、检察机关工作，2013年辞去公职从事律师工作。从业已来，办理各类民事案件数百件，并担任四川多家公司法律顾问。",
        },
      ],
      reasons: [
        {
          url: require("@/images/reason_01@2x.png"),
          title: "提升回款率",
          info: "修复失联当事人，可批量完成对其微信、支付宝、银行存款查控，快速通过案件办理测评。",
        },
        {
          url: require("@/images/reason_02@2x.png"),
          title: "新模式解决当前金融痛点",
          info: "逾期客户分布范围广，催收成本高、内委外风险大，逾期金额低、催收难度大，无可执行资产、逾期量大。",
        },
        {
          url: require("@/images/reason_03@2x.png"),
          title: "批量高效",
          info: "可批量处理资产信息，申请立案，执行，申请保全等业务节点任务。",
        },
        {
          url: require("@/images/reason_04@2x.png"),
          title: "全流程办理",
          info: "无缝衔接法院现有系统，信息交互0等待，真正实现一站式全流程服务。",
        },
        {
          url: require("@/images/reason_05@2x.png"),
          title: "与现有法院系统契合度高",
          info: "采用最高法信息化建设标准，已得到部分法院认可与支持。",
        },
        {
          url: require("@/images/reason_06@2x.png"),
          title: "专业技术团队赋能",
          info: "超100人技术团队支撑。",
        },
      ],
    };
  },
  mounted() {
    document.documentElement.style.fontSize = "14px";
  },
  methods: {
    changeMenuIndexEvent(index, el) {
      // if (el == "login") return;
      // this.menuIndex = index;
      if (el == "home") {
        document.scrollingElement.scrollTop = 0;
        this.show = false;
        return;
      }
      try {
        let top = document.getElementById(el).offsetTop;
        document.scrollingElement.scrollTop = top;
        this.show = false;
      } catch (error) {
        console.log("error", error);
      }
    },
    onChange(index) {
      this.current = index;
    },
    reasonCurrentChange(index) {
      this.reasonCurrent = index;
    },
  },
};
</script>
<style src="../assets/css/wap.css" scoped></style>
