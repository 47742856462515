<template>
    <div class="particles-js-box">
        <div id="particles-js"></div>
    </div>
</template>

<script>
    /* eslint-disable */
    import particlesJs from "particles.js";
    import particlesConfig from "./particles.json";
    export default {
        data() {
            return {};
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                particlesJS("particles-js", particlesConfig);
            }
        }
  } ;
</script>

<style scoped>
    .particles-js-box{
        width: 100%;
        height: 100%;
        top:0;
        left:0; 
    }
    #particles-js{
        background-color:#EEF3F7;
        width: 100%;
        height: 100%;
    }
</style>