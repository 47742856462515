import Vue from 'vue'
import App from './App.vue'
import wow from "wowjs"
import animated from "animate.css"
import router from './router'
import store from './store'
import "../src/assets/css/reset.css";
import {Popup,Swipe, SwipeItem} from "vant";
import 'vant/lib/index.css';
import "@/assets/js/browser.js";
import MetaInfo from 'vue-meta-info'
Vue.use(Popup).use(Swipe).use(SwipeItem).use(MetaInfo)
Vue.use(animated)
Vue.config.productionTip = false
Vue.prototype.$wow = wow
new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')