import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Wap from "../views/wap.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path:"/wap",
    name:"Wap",
    component:Wap
  }
]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
